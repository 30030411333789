import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';

import { AppActions, MapLayerActions, ScenarioActions } from '@store/actions/';

import { ConfigService } from '@core/services/config.service';
import { UtilService } from '@core/services/util.service';
import { CostLayerParams } from '@core/models/cost-layer-params.model';

@Injectable()
export class MapLayerEffects {
  loadMapLayerConfig$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppActions.enter),
      map(() =>
        MapLayerActions.loadMapLayerConfigSuccess({
          mapLayerConfig: this.configService.config.mapLayers,
        })
      ),
      catchError(error =>
        of(MapLayerActions.loadMapLayerConfigFailure({ error }))
      )
    );
  });

  updateLayerStyle$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScenarioActions.loadScenarioSuccess),
      switchMap(({ scenario }) =>
        this.utilService.createPaintExpression(scenario).pipe(
          map((params: CostLayerParams[]) =>
            MapLayerActions.updateLayerPaintColour({ params })
          ),
          catchError(error => of(error))
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private configService: ConfigService,
    private utilService: UtilService
  ) {}
}
