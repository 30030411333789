import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

export const APP_ROUTES: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('@landing-page/landing-page.module').then(
        module => module.LandingPageModule
      ),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('@dashboard/dashboard.module').then(
        module => module.DashboardModule
      ),
    canActivate: [MsalGuard],
  },
];
