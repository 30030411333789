import { InjectionToken } from '@angular/core';
import {
  Action,
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer,
} from '@ngrx/store';

import { MapLayerConfig } from '@core/models/map-layer-config.model';

import * as fromApp from '@store/reducers/app.reducer';
import * as fromMapLayers from '@store/reducers/map-layers.reducer';
import * as fromScenarios from '@store/reducers/scenario.reducer';

import { environment } from '../../environments/environment';
import { CostLayerParams } from '@core/models/cost-layer-params.model';

export interface State {
  [fromApp.appFeatureKey]: fromApp.State;
  [fromMapLayers.mapLayersFeatureKey]: fromMapLayers.State;
  [fromScenarios.scenarioFeatureKey]: fromScenarios.State;
}

export const ROOT_REDUCERS = new InjectionToken<
  ActionReducerMap<State, Action>
>('Root reducers token', {
  factory: () => ({
    [fromApp.appFeatureKey]: fromApp.reducer,
    [fromMapLayers.mapLayersFeatureKey]: fromMapLayers.reducer,
    [fromScenarios.scenarioFeatureKey]: fromScenarios.reducer,
  }),
});

export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state, action) => {
    const result = reducer(state, action);
    console.groupCollapsed(action.type);
    console.log('prev state', state);
    console.log('action', action);
    console.log('next state', result);
    console.groupEnd();

    return result;
  };
}
export const metaReducers: MetaReducer<State>[] = !environment.production
  ? [logger]
  : [];

/** App theme */
export const selectAppState = createFeatureSelector<fromApp.State>(
  fromApp.appFeatureKey
);

export const selectTheme = createSelector(selectAppState, fromApp.getTheme);

/** Map Layer Selectors */
export const selectMapLayerState = createFeatureSelector<fromMapLayers.State>(
  fromMapLayers.mapLayersFeatureKey
);

export const selectMapLayerConfig = createSelector(
  selectMapLayerState,
  fromMapLayers.getMapLayerConfig
);

export const selectContextLayerConfig = createSelector(
  selectMapLayerConfig,
  (layers: MapLayerConfig[]) =>
    layers.filter(layer => layer.layerType !== 'Cost')
);

export const selectCostLayerConfig = createSelector(
  selectMapLayerConfig,
  (layers: MapLayerConfig[]) =>
    layers.filter(layer => layer.layerType === 'Cost')
);

export const selectCurrentCostLayer = createSelector(
  selectCostLayerConfig,
  (layers: MapLayerConfig[]) =>
    layers.find(layer => layer.layout.visibility === 'visible')
);

export const selectCostLayerParams = createSelector(
  selectMapLayerState,
  fromMapLayers.getCostLayerParams
);

export const selectCurrentCostLayerParams = createSelector(
  selectCurrentCostLayer,
  selectCostLayerParams,
  (
    currentCostLayer: MapLayerConfig | undefined,
    costLayerParams: CostLayerParams[]
  ) =>
    currentCostLayer &&
    costLayerParams.find(param => param.layerId === currentCostLayer.id)
);

/** Scenario Selectors */
export const selectScenarioState = createFeatureSelector<fromScenarios.State>(
  fromScenarios.scenarioFeatureKey
);

export const selectScenario = createSelector(
  selectScenarioState,
  fromScenarios.getScenario
);

export const selectScenarioLoading = createSelector(
  selectScenarioState,
  fromScenarios.getScenarioLoading
);

export const selectScenarioLoaded = createSelector(
  selectScenarioState,
  fromScenarios.getScenarioLoaded
);

export const selectScenarioFiltering = createSelector(
  selectScenarioState,
  fromScenarios.getScenarioFiltering
);

export const selectScenarioConfig = createSelector(
  selectScenarioState,
  fromScenarios.getScenarioConfig
);

export const selectScenarioLayerLayout = createSelector(
  selectScenarioConfig,
  (scenarioConfig: MapLayerConfig) => scenarioConfig.layout
);

export const selectSelectedScenario = createSelector(
  selectScenarioState,
  fromScenarios.getSelectedScenario
);

/** all summaries */
export const selectScenarioSummaries = createSelector(
  selectScenarioState,
  fromScenarios.getScenarioSummaries
);
