import { CostLayerParams } from '@core/models/cost-layer-params.model';
import { MapLayerConfig } from '@core/models/map-layer-config.model';

import { createAction, props } from '@ngrx/store';

export const loadMapLayerConfig = createAction('[Map] Load Map Layer Config');

export const loadMapLayerConfigSuccess = createAction(
  '[Constraint] Load Constraint Config Success',
  props<{ mapLayerConfig: MapLayerConfig[] }>()
);

export const loadMapLayerConfigFailure = createAction(
  '[Constraint] Load Constraint Config Failure',
  props<{ error: any }>()
);

export const toggleCostLayerVisibility = createAction(
  '[Controls] Toggle Cost Layer Visibility',
  props<{ layerId: string }>()
);

export const updateLayerPaintColour = createAction(
  '[Scenario Load] Update Cost Layer Paint Colour',
  props<{
    params: CostLayerParams[];
  }>()
);
