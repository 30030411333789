import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { createProviders } from './app/utils/msal';

import { ConfigService } from '@core/services/config.service';
import { ConfigModel } from '@core/models/config.model';
import { CONFIG } from '@core/tokens/config.token';

import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

ConfigService.load().then((config: ConfigModel) => {
  /* create msal providers */
  const msalProviders = createProviders(config);
  const providers = [{ provide: CONFIG, useValue: config }, ...msalProviders];
  platformBrowserDynamic(providers)
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
});
