import { createReducer, on } from '@ngrx/store';

import { AppActions } from '@store/actions';
import { Theme } from '@core/models/theme.model';

export const appFeatureKey = 'app';

export interface State {
  theme: Theme;
}

export const initialState: State = {
  theme: 'light',
};

export const reducer = createReducer(
  initialState,
  on(
    AppActions.updateTheme,
    (state, { theme }): State => ({
      ...state,
      theme,
    })
  )
);

export const getTheme = (state: State) => state.theme;
