import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, of } from 'rxjs';
import { Store } from '@ngrx/store';

import {
  ScenarioActions,
  AppActions,
  ScenarioSummariesActions,
} from '@store/actions';

import { DataService } from '@core/services/data.service';
import { ConfigService } from '@core/services/config.service';

import { ScenarioModel } from '@core/models/scenario.model';
import { ScenarioSummary } from '@core/models/scenario-summary.model';

@Injectable()
export class ScenarioEffects {
  /**
   * Load scenario data when app loads
   */
  loadScenario$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ScenarioSummariesActions.setSelectedScenario),
      switchMap(({ selectedScenario }) =>
        this.dataService.loadScenario(selectedScenario.scenario).pipe(
          map((scenario: ScenarioModel[]) =>
            ScenarioActions.loadScenarioSuccess({ scenario })
          ),
          catchError(error =>
            of(ScenarioActions.loadScenarioFailure({ error }))
          )
        )
      )
    );
  });

  /**
   * Load scenario summaries when app loads
   */
  loadSummaries$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppActions.enter),
      switchMap(() =>
        this.dataService.loadScenarioDescriptions().pipe(
          map((summaries: ScenarioSummary[]) =>
            ScenarioSummariesActions.loadSummariesSuccess({ summaries })
          ),
          catchError(error =>
            of(ScenarioSummariesActions.loadSummariesFailure({ error }))
          )
        )
      )
    );
  });

  loadScenarioConfig$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppActions.enter),
      map(() =>
        ScenarioActions.loadScenarioConfigSuccess({
          scenarioConfig: this.configService.config.scenarios,
        })
      ),
      catchError(error =>
        of(ScenarioActions.loadScenarioConfigFailure({ error }))
      )
    );
  });

  constructor(
    private actions$: Actions,
    private configService: ConfigService,
    private dataService: DataService
  ) {}
}
