import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';

import { AppInsightsService } from './services/app-insights.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MsalInterceptor, MsalModule, MsalService } from '@azure/msal-angular';

@NgModule({
  declarations: [],
  imports: [CommonModule, MsalModule],
  providers: [
    AppInsightsService,
    {
      provide: ErrorHandler,
      useClass: ApplicationinsightsAngularpluginErrorService,
    },
    /* non static msal providers */
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    MsalService,
  ],
})
export class CoreModule {
  constructor(private insights: AppInsightsService) {}
}
