import { MapLayerConfig } from '@core/models/map-layer-config.model';
import { ScenarioSummary } from '@core/models/scenario-summary.model';
import { ScenarioModel } from '@core/models/scenario.model';
import { createReducer, on } from '@ngrx/store';
import {
  AppActions,
  ScenarioActions,
  ScenarioSummariesActions,
} from '@store/actions';

export const scenarioFeatureKey = 'scenario';

export interface State {
  scenarioConfig: MapLayerConfig;
  filtering: boolean;
  scenario: ScenarioModel[];
  selectedScenario: ScenarioSummary | undefined;
  summaries: ScenarioSummary[];
  loaded: boolean;
  loading: boolean;
}

export const initialState: State = {
  scenarioConfig: undefined as any,
  filtering: false,
  scenario: [],
  selectedScenario: undefined,
  loaded: false,
  loading: false,
  summaries: [],
};

export const reducer = createReducer(
  initialState,
  on(
    AppActions.enter,
    (state): State => ({
      ...state,
      loading: true,
    })
  ),
  on(
    ScenarioSummariesActions.setSelectedScenario,
    (state, { selectedScenario }): State => ({
      ...state,
      selectedScenario,
      loading: true,
    })
  ),
  on(
    ScenarioActions.loadScenarioSuccess,
    (state, { scenario }): State => ({
      ...state,
      scenario,
      loading: false,
      loaded: true,
    })
  ),
  on(
    ScenarioActions.loadScenarioFailure,
    (state): State => ({
      ...state,
      loaded: false,
      loading: false,
    })
  ),
  on(
    ScenarioActions.loadScenarioConfigSuccess,
    (state, { scenarioConfig }): State => ({
      ...state,
      scenarioConfig,
    })
  ),
  on(
    ScenarioSummariesActions.loadSummariesSuccess,
    (state, { summaries }): State => ({
      ...state,
      summaries,
      loaded: true,
      loading: false,
    })
  ),
  on(
    ScenarioSummariesActions.loadSummariesFailure,
    (state): State => ({
      ...state,
      loaded: false,
      loading: false,
    })
  )
);

export const getScenario = (state: State) => state.scenario;
export const getScenarioConfig = (state: State) => state.scenarioConfig;
export const getSelectedScenario = (state: State) => state.selectedScenario;
export const getScenarioSummaries = (state: State) => state.summaries;
export const getScenarioLoaded = (state: State) => state.loaded;
export const getScenarioLoading = (state: State) => state.loading;
export const getScenarioFiltering = (state: State) => state.filtering;
