import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  ViewChild,
  Renderer2,
  ChangeDetectionStrategy,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ArcContainer } from '@arc-web/components';
import '@microsoft/mgt-components/dist/es6/components/mgt-login/mgt-login';
import { Store } from '@ngrx/store';

import { AppActions } from './store/actions';
import { Theme } from '@core/models/theme.model';
import { ConfigService } from '@core/services/config.service';

@Component({
  selector: 'scale-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements AfterViewInit {
  title = this.configService.config.title;
  @ViewChild('container') container!: ElementRef<ArcContainer>;

  constructor(
    private configService: ConfigService,
    private store: Store,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2
  ) {}

  ngAfterViewInit(): void {
    this.container.nativeElement.addEventListener(
      'arc-accessibility-change',
      this.accessibilityChanged.bind(this)
    );
  }

  accessibilityChanged(event: any) {
    const theme = event.detail.preferences.theme;
    if (theme === 'auto')
      this.setTheme(
        window.matchMedia &&
          window.matchMedia('(prefers-color-scheme: light)').matches
          ? 'light'
          : 'dark'
      );
    else this.setTheme(theme);
  }

  setTheme(theme: Theme) {
    this.renderer.setAttribute(this.document.body, 'theme', theme);
    this.renderer.addClass(this.document.body, theme);
    this.renderer.removeClass(
      this.document.body,
      theme === 'light' ? 'mgt-dark' : 'mgt-light'
    );
    this.renderer.addClass(
      this.document.body,
      theme === 'light' ? 'mgt-light' : 'mgt-dark'
    );
    this.container.nativeElement.setAttribute('theme', theme);
    this.store.dispatch(AppActions.updateTheme({ theme }));
  }
}
