import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, forkJoin, map, Observable, throwError } from 'rxjs';
import { Feature, FeatureCollection } from 'geojson';

import { ConfigService } from './config.service';

import { ScenarioModel } from '@core/models/scenario.model';
import { MapLayerConfig } from '@core/models/map-layer-config.model';
import { ScenarioSummary } from '@core/models/scenario-summary.model';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private mapLayerConfig: MapLayerConfig[];

  constructor(
    private configService: ConfigService,
    private httpClient: HttpClient
  ) {
    this.mapLayerConfig = this.configService.config.mapLayers;
  }

  loadScenarioDescriptions(): Observable<ScenarioSummary[]> {
    return this.httpClient
      .get<ScenarioSummary[]>(
        `${this.configService.config.apiUrl}/api/scenario-select/${this.configService.config.scenarioSummaries.filename}`
      )
      .pipe(catchError(this.handleError));
  }

  loadScenario(scenarioName: string): Observable<ScenarioModel[]> {
    const name = scenarioName
      .replace(/\(|\)/g, '')
      .replace(/ - /g, '-')
      .replace(/ /g, '_');
    return this.httpClient
      .get<ScenarioModel[]>(
        `${this.configService.config.apiUrl}/api/scenario-select/${name}`
      )
      .pipe(catchError(this.handleError));
  }

  /**
   * Loads all spatial data
   * @returns FeatureCollection[] Array of geojson
   */
  loadSpatialData(): Observable<FeatureCollection[]> {
    const requests = this.mapLayerConfig
      .filter((layer: MapLayerConfig) => layer.layerType !== 'Cost')
      .map((mapLayerConfig: MapLayerConfig) =>
        this.httpClient.get<FeatureCollection>(
          `assets/data/${mapLayerConfig.filename}`
        )
      );
    /** combine spatial data with layer config data */
    return forkJoin(requests).pipe(
      map((data: FeatureCollection[]) => data),
      catchError(this.handleError)
    );
  }

  /**
   * Convert scenario data to geojson
   * @param scenario scenarios to convert to geojson
   * @returns feature collection of scenario points
   */
  toGeoJSON(scenario: ScenarioModel[]): FeatureCollection {
    const features = scenario.map((s: ScenarioModel) => {
      return {
        type: 'Feature',
        properties: {
          id: s.cell_id,
          foundation_centroid_production: s.foundation_centroid_production,
          lcoe: +s.lcoe,
          loss_norm: +s.loss_norm,
          scenario: s.scenario,
          deployed: s.deployed,
        },
        geometry: {
          type: 'Point',
          coordinates: [+s.long, +s.lat],
        },
      } as Feature;
    });
    return {
      type: 'FeatureCollection',
      features,
    };
  }

  /**
   * Error handler
   * @param err Http error response
   * @returns error
   */
  private handleError(err: HttpErrorResponse): Observable<never> {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(() => new Error(errorMessage));
  }
}
