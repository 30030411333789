import { StaticProvider } from '@angular/core';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
  ProtectedResourceScopes,
} from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  InteractionType,
  PublicClientApplication,
} from '@azure/msal-browser';

import { ConfigModel } from '@core/models/config.model';

function createClient(config: ConfigModel): PublicClientApplication {
  const { auth } = config;
  return new PublicClientApplication({ auth });
}

function createGuardConfig(): MsalGuardConfiguration {
  return <MsalGuardConfiguration>{
    interactionType: InteractionType.Popup,
    authRequest: {
      scopes: ['user.read'],
    },
  };
}

function createInterceptorConfig(
  config: ConfigModel
): MsalInterceptorConfiguration {
  const url = config.apiUrl;
  const { clientId } = config.auth;
  const resources = new Map<string, (string | ProtectedResourceScopes)[]>();
  resources.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
  resources.set('https://graph.microsoft.com/v1.0/users ', ['user.read']);
  resources.set(`${url}/*`, [`${clientId}/api.read`]);
  return <MsalInterceptorConfiguration>{
    interactionType: InteractionType.Popup,
    protectedResourceMap: resources,
  };
}

export function createProviders(config: ConfigModel): StaticProvider[] {
  return [
    {
      provide: MSAL_INSTANCE,
      useValue: createClient(config),
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useValue: createGuardConfig(),
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useValue: createInterceptorConfig(config),
    },
  ];
}
