<arc-container #container theme="light">
  <arc-navbar #navbar home="/" slot="nav" logo="assets/rwe-site-logo.svg">
    <span slot="name">{{ title }}</span>
    <arc-button type="tab" slot="user">
      <mgt-login></mgt-login>
    </arc-button>
  </arc-navbar>
  <main>
    <router-outlet class="content"></router-outlet>
  </main>
</arc-container>
