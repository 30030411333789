import { Injectable, inject } from '@angular/core';
import { ConfigService } from './config.service';

import { scaleSequential } from 'd3-scale';
import { interpolateViridis } from 'd3-scale-chromatic';
import { ScenarioModel } from '@core/models/scenario.model';
import { Observable, of } from 'rxjs';
import { LayerPaint } from '@core/models/layer-paint.model';
import { MapLayerConfig } from '@core/models/map-layer-config.model';
import { Expression } from 'mapbox-gl';
import { CostLayerParams } from '@core/models/cost-layer-params.model';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  private readonly configService = inject(ConfigService);

  /**
   * Creates a Mapbox layer expression for cost layers.  Used to
   * dynamically create the layer paint expression based on the
   * data range of the provided scenario
   * @param scenario SCALE scenario
   * @returns paint expressions for LCOE and Loss function layers
   */
  createPaintExpression(
    scenario: ScenarioModel[]
  ): Observable<CostLayerParams[]> {
    const expressions = [] as CostLayerParams[];
    const layers = this.configService.config.mapLayers as MapLayerConfig[];
    // get cost layers
    const costLayers = layers.filter(
      (layer: MapLayerConfig) => layer.layerType === 'Cost'
    );
    costLayers.forEach((layer: MapLayerConfig) => {
      /** property of the data to visualise */
      const dataProperty = layer.dataProperty;
      /** setup the interpolate expression for */
      const exp = ['interpolate', ['linear'], ['get', dataProperty]];
      /** get the min and max values of the data property */
      const { minVal, maxVal } = this.getValues(scenario, dataProperty!);
      /** create equal steps between min and max values */
      const steps = this.returnAllSteps(+minVal, +maxVal, 10);
      /** map steps to colours */
      const colours = this.getColours(+minVal, +maxVal, steps);
      /** add colours and values to paint expression */
      exp.push(...(colours as string[]));
      expressions.push({
        layerId: layer.id,
        minVal: +minVal,
        maxVal: +maxVal,
        colours,
        expression: exp as Expression,
      });
    });
    return of(expressions);
  }

  /**
   * Create steps (values) between a min and max
   * min = 1
   * max = 10
   * totalSteps = 10
   * returned steps = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
   * @param min step range min value
   * @param max step range max value
   * @param totalSteps number of steps
   * @returns array of steps from min to max value
   */
  returnAllSteps(min: number, max: number, totalSteps: number): number[] {
    let steps = new Array(totalSteps);
    let distance = max - min;
    for (let i = 0; i < totalSteps; i++) {
      steps[i] = min + distance * (i / (totalSteps - 1));
    }
    return steps;
  }

  /**
   * Create sequential scale between min and max values
   * then get the Viridis colour at the given value
   * @param domainMin
   * @param domainMax
   * @param value
   * @returns colour from the Viridis colour palette as hex code
   */
  calculateViridisColours(
    domainMin: number,
    domainMax: number,
    value: number
  ): string {
    const interpolateViridisColours = scaleSequential(
      interpolateViridis
    ).domain([domainMin, domainMax]);
    return interpolateViridisColours(value);
  }

  /**
   * Get all colours for a set of input values
   * @param minVal min value
   * @param maxVal max value
   * @param steps stepped values between min & max
   * @returns array of steps mapped to colours
   */
  private getColours(minVal: number, maxVal: number, steps: number[]) {
    const colours: Array<number | string> = [];
    steps.forEach((step: number) => {
      const color = this.calculateViridisColours(minVal, maxVal, step);
      colours.push(step);
      colours.push(color);
    });
    return colours;
  }

  /**
   * Calculate min/max value for a given scenarion data property
   * @param scenario selected SCALE scenario
   * @param property data property to get min/max value for
   * @returns min/max values for the scenario data property
   */
  private getValues(scenario: ScenarioModel[], property: string) {
    const minVal = scenario.reduce(
      (acc, curr) =>
        +curr[property as keyof ScenarioModel] <
        +acc[property as keyof ScenarioModel]
          ? curr
          : acc,
      scenario[0] || undefined
    );
    const maxVal = scenario.reduce(
      (acc, curr) =>
        +curr[property as keyof ScenarioModel] >
        +acc[property as keyof ScenarioModel]
          ? curr
          : acc,
      scenario[0] || undefined
    );

    return {
      minVal: minVal[property as keyof ScenarioModel],
      maxVal: maxVal[property as keyof ScenarioModel],
    };
  }
}
