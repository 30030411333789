import { createReducer, on } from '@ngrx/store';

import { MapLayerActions } from '@store/actions';
import { MapLayerConfig } from '@core/models/map-layer-config.model';
import { LayerPaint } from '@core/models/layer-paint.model';
import { CirclePaint } from 'mapbox-gl';
import { CostLayerParams } from '@core/models/cost-layer-params.model';

export const mapLayersFeatureKey = 'layers';

export interface State {
  mapLayerConfig: MapLayerConfig[];
  costLayerParams: CostLayerParams[];
}

export const initialState: State = {
  mapLayerConfig: [],
  costLayerParams: [],
};

export const reducer = createReducer(
  initialState,
  on(
    MapLayerActions.loadMapLayerConfigSuccess,
    (state, { mapLayerConfig }): State => ({
      ...state,
      mapLayerConfig,
    })
  ),
  on(MapLayerActions.toggleCostLayerVisibility, (state, { layerId }): State => {
    const otherLayers = state.mapLayerConfig.filter(
      layer => layer.layerType !== 'Cost'
    );
    const mapLayerConfig: MapLayerConfig[] = state.mapLayerConfig
      .filter(layer => layer.layerType === 'Cost')
      .map(layer => {
        if (layer.id === layerId) {
          return {
            ...layer,
            layout: {
              visibility:
                layer.layout.visibility === 'visible' ? 'none' : 'visible',
            },
          };
        } else {
          return {
            ...layer,
            layout: {
              visibility: 'none',
            },
          };
        }
      });
    return {
      ...state,
      mapLayerConfig: [...otherLayers, ...mapLayerConfig],
    };
  }),
  on(MapLayerActions.updateLayerPaintColour, (state, { params }): State => {
    const mapLayerConfig = updateLayerPaint(state.mapLayerConfig, params);
    return { ...state, mapLayerConfig, costLayerParams: params };
  })
);

function updateLayerPaint(
  mapLayerConfig: MapLayerConfig[],
  params: CostLayerParams[]
) {
  const layers = mapLayerConfig.map(layer => {
    const exp = params.find(e => e.layerId === layer.id);
    if (!exp) return layer;
    return {
      ...layer,
      paintExpression: {
        ...layer.paintExpression,
        'circle-color': exp.expression,
      } as CirclePaint,
    };
  });
  return layers;
}

export const getMapLayerConfig = (state: State) => state.mapLayerConfig;
export const getCostLayerParams = (state: State) => state.costLayerParams;
